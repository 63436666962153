import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";

class Spaces extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>Prayer - Gracehouse Church Logan</title>
          <meta
            property="og:title"
            content="Prayer - Gracehouse Church Logan"
          />
          <meta
            name="description"
            content="We're a Christian community that believes in the power of prayer. If you need prayer for anything, we'd love to serve you in prayer."
          />
          <meta
            property="og:description"
            content="We're a Christian community that believes in the power of prayer. If you need prayer for anything, we'd love to serve you in prayer."
          />
        </Helmet>
        <section className="hire-form">
          <div className="container">
            <h2>Can I Pray For <span>You?</span></h2>
            <p>We're a Christian community that believes in the power of prayer. If you need prayer for anything, we'd love to serve you in prayer. Whether it's health issues, relational struggles, or maybe "life" is hitting a little hard right now. Let us pray for you.</p>
            <p>Your prayer request will be prayed for by our trusted team and we'll follow up via email if you'd like us to continue praying for you in a few weeks time.</p>
            <div className="hire-form-content">
              <section className="hire-form__form">
                <form
                  name="Prayer Requests"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="Prayer Requests"
                  />
                  <input
                    type="text"
                    name="fullName"
                    required
                    placeholder="Name"
                  />

                  <input
                    type="text"
                    name="contactEmail"
                    required
                    placeholder="Email"
                  />
                  <textarea
                    name="prayerRequest"
                    cols="30"
                    rows="7"
                    placeholder="Prayer Request"
                  ></textarea>
                  
                  <button type="submit">Submit Request</button>
                </form>
              </section>
              <section className="hire-form__extra">
                <p>Alternatively, you can email us below.</p>
                <a href="mailto:admin@gracehouse.com.au" className="link">
                  admin@gracehouse.com.au
                </a>
              </section>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Spaces;

export const pageQuery = graphql`
  query PrayerQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
