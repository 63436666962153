// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-giving-js": () => import("./../../../src/pages/giving.js" /* webpackChunkName: "component---src-pages-giving-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prayer-js": () => import("./../../../src/pages/prayer.js" /* webpackChunkName: "component---src-pages-prayer-js" */),
  "component---src-pages-sermons-js": () => import("./../../../src/pages/sermons.js" /* webpackChunkName: "component---src-pages-sermons-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-whats-on-js": () => import("./../../../src/pages/whats-on.js" /* webpackChunkName: "component---src-pages-whats-on-js" */),
  "component---src-templates-sermon-js": () => import("./../../../src/templates/sermon.js" /* webpackChunkName: "component---src-templates-sermon-js" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */)
}

